.App {
  text-align: center;  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 18vh;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #0066CC;
}

.App-title{  
  font-size: calc(20px + 2vmin);
}

.App-header-menu-item a{
  color: #0066CC; /* White menu link text */
  text-decoration: none;
  margin-left: 20px;
}

.App-header-menu-item:hov{
  color: #61dafb;
}

.App-header-menu-item{
  display: flex;
}

.app-desc{
  display: flex;
  justify-content: center;
}

.app-desc p{
  width: 90%;
  font-weight: 600;
}

.feedback-link{  
  color: aliceblue !important;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1E90FF;
  border: none;
  cursor: pointer;
}

.menu-link a{
  color: #007bff; 
  font-weight: bold;
}

.form-actions {
  display: flex;
  justify-content: space-around;
}

.container {
     width: 50% !important;
    /* border: 1px solid #ccc; */
    border-radius: 0.5rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 1rem !important;
    background-color: rgb(251 251 251 / 90%);
  }

textarea {
  width: 100%;
  resize: vertical;
  border: none;
  border-radius: 1rem;
}

input[type='file'] {
  margin-bottom: 10px;
}

.submit-btn {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1E90FF;
  color: #fff;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.error-msg{
  color: red;
}

.ag-theme-alpine{
  padding: 4rem;
  width: 100% !important;
}

.ag-root-wrapper{
  width: auto;
}
.results{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.disabled{
  opacity: 0.5; 
  cursor: not-allowed;
}
.progress{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
}

.Footer {
  background-color: #f0f0f0; /* Background color for the footer */
  padding: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.Footer p {
  margin: 0; /* Remove default margin for <p> */
}

.FooterHighlight {
  color: #007bff; /* Highlight color */
  font-weight: bold; /* Make the text bold */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
